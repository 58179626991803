<template>
  <div class="header">
    <h1>{{ this.$t("mypage.qna") }}</h1>
    <ul class="path">
      <li>
        <router-link to="/mypage">{{ this.$t("mypage.mypage") }}</router-link>
      </li>
      <li class="current">
        <router-link to="/mypage/qna">{{ this.$t("mypage.qna") }}</router-link>
      </li>
    </ul>
  </div>
  <div class="body">
    <div class="tab_wrap">
      <div class="tab" @click="this.$router.push('/mypage/review')">
        {{ this.$t("mypage.review") }}
      </div>
      <div class="tab selected">
        {{ this.$t("mypage.qna") }}
      </div>
    </div>
    <div class="search_wrap">
      <div class="date">
        <input type="date" v-model="this.search_start_date" /><span>-</span
        ><input type="date" v-model="this.search_end_date" />
        <button class="button middle red search" @click="click_search">
          {{ this.$t("common.search") }}
        </button>
      </div>
    </div>

    <!-- Q&A -->
    <ul class="qna">
      <li v-if="this.qna_data.length <= 0" class="empty">
        {{ this.$t("product.detail.no_qna") }}
      </li>
      <li v-for="(value, index) in this.qna_data" :key="index">
        <div class="top">
          <div class="name">
            <router-link
              :to="{ path: '/product/detail/' + value.product_id }"
              >{{ value.product_name }}</router-link
            >
          </div>
          <div class="status">
            {{
              value.question_status == "ANSWERED"
                ? this.$t("product.detail.qna_answered")
                : this.$t("product.detail.qna_unanswered")
            }}
          </div>
          <div class="date">
            {{
              this.common.date_long_format(
                this.common.date_parse(value.question_register_date)
              )
            }}
          </div>
        </div>
        <div class="question">
          <img
            class="lock"
            src="@/assets/img/icon_lock.svg"
            v-if="value.secret == 'YES'"
          />
          {{
            value.is_hidden == "YES"
              ? this.$t("product.detail.secret_content")
              : value.question_content
          }}
        </div>
        <div class="answer" v-if="value.question_status == 'ANSWERED'">
          <div class="top">
            <div class="name">{{ value.seller_name }}</div>
            <div class="date">
              {{
                value.answer_register_date == null ||
                value.answer_register_date == ""
                  ? ""
                  : this.common.date_long_format(
                      this.common.date_parse(value.answer_register_date)
                    )
              }}
            </div>
          </div>
          <div class="body">
            <img
              class="lock"
              src="@/assets/img/icon_lock.svg"
              v-if="value.secret == 'YES'"
            />{{
              value.is_hidden == "YES"
                ? this.$t("product.detail.secret_content")
                : value.answer_content
            }}
          </div>
        </div>
      </li>
    </ul>
    <!--// Q&A -->

    <button
      class="button outline small sharp compact more"
      v-if="this.qna_data.length && !this.data_fully_loaded"
      @click="load_qna_data"
    >
      {{ this.$t("common.load_more") }}
    </button>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "MyReview",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.myqna"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.myqna"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      data_fully_loaded: false,
      search_start_date: "",
      search_end_date: "",
      search_page: 1,
      qna_data: [],
      qna_total_count: 0,
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.load_qna_data();
  },
  methods: {
    // Q&A 데이터 가져오기
    load_qna_data: function () {
      if (!this.storage.is_logged_in()) return;

      // 이미 다 불러왔으면 진행안함.
      if (this.data_fully_loaded) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/qna/list",
          {
            user_id: this.storage.get_user_id(),
            search_start_date: this.search_start_date,
            search_end_date: this.search_end_date,
            page: this.search_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let resp_data = response.data;

          // 첫 페이지에서는 데이터 초기화
          if (self.search_page <= 1) {
            self.qna_data = [];
          }

          // 리뷰 데이터가 있다면...
          if (typeof resp_data != "undefined" && resp_data != null) {
            for (let i = 0; i < resp_data.length; i++) {
              self.qna_data.push(resp_data[i]);
            }

            if (resp_data.length <= 0) {
              self.data_fully_loaded = true;
            } else {
              self.search_page++; // 페이지 수 늘리기
            }

            // 총 갯수 정보 헤더에서 가져오기
            try {
              let str_info = response.headers.wkt_extra_info;
              let extra_info = JSON.parse(str_info);
              self.qna_total_count = parseInt(extra_info.total_count);
            } catch (e) {
              console.log(e);
            }
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_qna_data

    // 검색 버튼 이벤트 핸들러
    click_search: function () {
      this.search_page = 1;
      this.data_fully_loaded = false;

      this.load_qna_data();
    },
  },
};
</script>

<style scoped>
.body > .tab_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 0 2rem 0;

  border-bottom: 1px solid #333;
}
.body > .tab_wrap > .tab {
  width: 50%;
  padding: 1rem 1.3rem;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  background: #f4f4f4;
  cursor: pointer;
}
.body > .tab_wrap > .tab.selected {
  background: #333;
  color: #fff;
}

.body > .search_wrap {
  padding: 1.2rem 1.6rem;
  margin: 0 0 1rem 0;
  border-radius: 0.8rem;
  background: #f4f4f4;
}
.body > .search_wrap > .date {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.body > .search_wrap > .date > input[type="date"] {
  width: 35%;
}
.body > .search_wrap > .date > span {
  margin: 0 1rem;
}
.body > .search_wrap > .date > button.button {
  letter-spacing: 0;
  width: 20%;
  margin-left: 1rem;
  font-size: 1rem;
  padding: 0.8rem;
}

.body > ul.qna {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.body > ul.qna > li {
  display: block;
  margin: 0;
  padding: 1rem 0;
  border-bottom: 1px solid #dedede;
}
.body > ul.qna > li.empty {
  text-align: center;
  padding: 3rem 0;
}
.body > ul.qna > li > .top {
  position: relative;
  padding: 0 0 0.8rem 0;
}
.body > ul.qna > li > .top > .name {
  display: inline-block;
  font-weight: 700;
}
.body > ul.qna > li > .top > .status {
  display: inline-block;

  padding: 0.3rem 0.6rem;
  margin: 0 0 0 1rem;

  font-size: 0.5rem;
  font-weight: 300;
  background: #333;
  color: #fff;
  border-radius: 0.5rem;
}
.body > ul.qna > li > .top > .date {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);
  color: #808080;
}
.body > ul.qna > li > .top a {
  color: #000;
  text-decoration: none;
}
.body > ul.qna > li > .top a:hover {
  text-decoration: underline;
}

.body > ul.qna > li > .question {
  padding: 0 0 1rem 0;
  white-space: pre-line;
}
.body > ul.qna > li > .question > img.lock {
  vertical-align: middle;
  height: 1rem;
  margin-right: 0.4rem;
}
.body > ul.qna > li > .answer {
  background: #efefef;
  border-radius: 0.4rem;
  overflow: hidden;
}
.body > ul.qna > li > .answer > .top {
  position: relative;
  padding: 0.8rem 1rem;
}
.body > ul.qna > li > .answer > .top > .name {
  font-weight: 700;
}
.body > ul.qna > li > .answer > .top > .date {
  position: absolute;
  right: 1rem;
  bottom: 50%;
  transform: translate(0, 50%);
  color: #808080;
}
.body > ul.qna > li > .answer > .body {
  padding: 0 1rem 0.8rem 1rem;
  white-space: pre-line;
}
.body > ul.qna > li > .answer > .body > img.lock {
  vertical-align: middle;
  height: 1rem;
  margin-right: 0.4rem;
}

.body > button.more {
  display: block;
  margin: 2rem auto 0 auto;
}
</style>
